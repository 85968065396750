<template>
  <div />
</template>
<style>
@media all and (max-width: 1264px) {
  .rocketchat-widget {
    bottom: 82px !important;
  }
}
@media all and (max-width: 600px) {
  .rocketchat-widget {
    max-height: calc(100% - 142px) !important;
  }
}
</style>

<script>
import to from 'await-to-js';
import { mapMutations } from 'vuex';

export default {
  name: 'RocketChat',
  data() {
    return {};
  },
  computed: {
    providerId() {
      return this.$store.state.user.info.providerId || this.$store.state.registration.providerId;
    },
    widgetExists() {
      return !!document.getElementsByClassName('rocketchat-widget').length;
    },
  },
  methods: {
    ...mapMutations(['setNotification']),
    async isChatEnabled() {
      if (!this.providerId) return false;
      const { providerId } = this;
      const [errors, query] = await to(
        this.$apollo.query({
          query: await this.$store.getters.getQuery('IsChatEnabled'),
          variables: { providerId },
        })
      );

      if (errors) {
        return false;
      }
      return !!query?.data?.isChatEnabled;
    },
    async getChatSettings() {
      if (!this.providerId) return null;
      const { providerId } = this;
      const [errors, query] = await to(
        this.$apollo.query({
          query: await this.$store.getters.getQuery('GetChatSettings'),
          variables: { providerId },
        })
      );
      if (errors) {
        this.setNotification({
          color: 'var(--v-error-base)',
          text: this.$t('errors.noRocketChat'),
          showing: false,
          timeout: 5000,
        });
      }
      return query?.data?.getChatSettings;
    },
    async createChat() {
      const isChatEnabled = await this.isChatEnabled();
      if (!isChatEnabled) return;
      if (!this.widgetExists) {
        const { liveChatUrl } = await this.getChatSettings();
        if (liveChatUrl) {
          const rocketScript = document.createElement('script');
          rocketScript.type = 'text/javascript';

          const code = `
    (function(w, d, s, u) {
		w.RocketChat = function(c) { w.RocketChat._.push(c) }; w.RocketChat._ = []; w.RocketChat.url = u;
		var h = d.getElementsByTagName(s)[0], j = d.createElement(s);
		j.async = true; j.src = '${liveChatUrl}/rocketchat-livechat.min.js?_=201903270000';
		h.parentNode.insertBefore(j, h);
	})(window, document, 'script', '${liveChatUrl}');
  `;
          rocketScript.appendChild(document.createTextNode(code));
          document.body.appendChild(rocketScript);
        }
      }
    },
  },
  created() {
    this.createChat();
  },
  mounted() {
    this.$store.watch(
      (state) => state.registration.providerId,
      () => {
        this.createChat();
      }
    );
  },
};
</script>
